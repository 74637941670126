<template>
  <page-header-wrapper :title='this.$route.meta.title'>
    <a-card :bordered='false' title="基本信息" style='margin-bottom: 20px'>
      <a-descriptions :column="4">
        <a-descriptions-item label='商户'>{{profile.merchantName}}</a-descriptions-item>
        <a-descriptions-item label='负责人'>{{profile.masterName}}</a-descriptions-item>
        <a-descriptions-item label='手机号'>{{profile.mobile}}</a-descriptions-item>
        <a-descriptions-item label='授权编号'>{{profile.authCode}}</a-descriptions-item>
      </a-descriptions>
    </a-card>
    <a-card v-if="order.id" :bordered='false'>
      <span slot='title'>
         <span>订单信息</span>
<!--          <span style="margin-left: 50px; color:#909399;font-size: 14px;">商品折扣：</span><span class="color-red">{{order.discountDes}}</span>-->
        <span style="margin-left: 50px; color:#909399;font-size: 14px;">现金折扣：</span><span class="color-red">{{profile.cashBalance}}</span>
      </span>
      <span slot='extra'>
        <template>
          <a-space style="margin-left: 20px">
            <a-button type='primary' @click='initialDeliver1(order)'>
              确认收款
            </a-button>
            <a-popconfirm title='确定删除吗？' ok-text='确认' cancel-text='取消'
                          @confirm='handleDelete(order)'>
              <a href='#'>删除</a>
            </a-popconfirm>
          </a-space>
        </template>
      </span>

      <a-descriptions :column="4">
        <a-descriptions-item label='订单号'>{{order.id}}</a-descriptions-item>
        <a-descriptions-item label='下单时间'>{{order.createTime}}</a-descriptions-item>
        <a-descriptions-item label='下单方式'>{{order.operatorNickname}}</a-descriptions-item>
        <a-descriptions-item v-if="!order.backRecord" label='订单留言'><span style="color: #E62734;">{{ order.remark }}</span></a-descriptions-item>
        <a-descriptions-item v-else></a-descriptions-item>

        <a-descriptions-item label='购买人姓名'>
          <span v-if="order.memberShareholder=='1'" class="color-red">
            {{order.nickname}}
          </span>
          <span v-else>
             {{order.nickname}}
          </span>
        </a-descriptions-item>
        <a-descriptions-item label='购买人手机号'>{{order.memberMobile}}</a-descriptions-item>
        <a-descriptions-item label='购买人编码'>{{getUserCode(order.shareholder,order.memberType,order.memberLevel)}}</a-descriptions-item>
        <a-descriptions-item></a-descriptions-item>

        <a-descriptions-item label='推荐人姓名'>
          <span v-if="order.spreaderShareholder=='1'" class="color-red">
            {{order.spreaderMasterName}}
          </span>
          <span v-else>
             {{order.spreaderMasterName}}
          </span>
        </a-descriptions-item>
        <a-descriptions-item label='推荐人手机号'>{{order.spreaderMobile}}</a-descriptions-item>
        <a-descriptions-item label='推荐人编码'>{{getUserCode(order.shareholder,order.spreaderType,order.spreaderLevel)}}</a-descriptions-item>
        <a-descriptions-item label='品牌公司'>{{order.memberAreaName}}</a-descriptions-item>

        <template>
          <a-descriptions-item label='预付金额' v-if="order.quickOrder>0" :span="4">{{order.quickAmount}}</a-descriptions-item>
          <a-descriptions-item  v-else :span="4"></a-descriptions-item>
        </template>

        <template v-if="!order.isShowOne">
<!--          非后台录单显示-->
            <a-descriptions-item label='所属商户编码'>
              <span v-for="(item, index) in getAuthCodeList(order.authCode)" :key="index"
                    :style="{color: index == 1 ? '#1890ff' : ''}">{{ item }}</span>
            </a-descriptions-item>
            <a-descriptions-item label='负责人姓名'>{{order.masterName}}</a-descriptions-item>
            <a-descriptions-item label='商户名称'>{{order.merchantName}}</a-descriptions-item>
            <a-descriptions-item ></a-descriptions-item>
        </template>

          <template v-if="order.isShowOne">
            <!--            商户录单只显示订单金额-->
            <template v-if="order.merchantRecord">
                    <a-descriptions-item label='订单金额' :span="4">{{order.amount}}</a-descriptions-item>
            </template>
            <template v-else>
                    <a-descriptions-item label='订单金额'>{{order.amount}}</a-descriptions-item>

                    <a-descriptions-item label='购买人应付' v-if="order.merchantMember==order.member">{{order.originalMerchantAmount}}</a-descriptions-item>
                    <a-descriptions-item label='购买人应付' v-else>{{order.amount}}</a-descriptions-item>

                    <a-descriptions-item v-if="order.merchantMember==order.member" label='积分抵扣'>{{order.merchantDiscount}}</a-descriptions-item>
                    <a-descriptions-item v-else label='积分抵扣'>{{order.memberDiscount}}</a-descriptions-item>
                    <a-descriptions-item v-if="order.merchantMember && order.merchantMember==order.member" label='转账优惠'>{{order.bankTransDiscount}}</a-descriptions-item>
                    <a-descriptions-item v-else></a-descriptions-item>


                    <template v-if="order.merchantMember==order.member">
                      <!--           商户购买的订单-->
                      <a-descriptions-item label='购买人实付' ><span class="color-red">{{order.merchantAmount}}</span></a-descriptions-item>
                      <a-descriptions-item label='购买人支付方式'>{{order.merchantPaymentMethodName }}</a-descriptions-item>
                      <a-descriptions-item label='购买人支付时间' :span="2">{{order.merchantActualPaymentTime}}</a-descriptions-item>
                    </template>
                    <template v-else>
                      <!--           用户购买的订单-->
                      <a-descriptions-item label='购买人实付'><span class="color-red">{{order.consumeAmount}}</span></a-descriptions-item>
                      <a-descriptions-item label='购买人支付方式'>{{order.paymentMethodName }}</a-descriptions-item>
                      <a-descriptions-item label='购买人支付时间' :span="2">{{order.createTime}}</a-descriptions-item>
                    </template>
            </template>


          </template>

          <template v-if="order.isShowTwo">

                <template v-if="order.applyId">
                  <!--          首先判断为批量兑换订单,不显示商户相关信息，只显示，商户名称和负责人-->
                  <a-descriptions-item label='商户名称'>{{order.merchantName}}</a-descriptions-item>
                  <a-descriptions-item label='负责人' :span="4">{{order.masterName}}</a-descriptions-item>
                </template>
                <template v-else>
                  <a-descriptions-item label='商户原始应付'>{{order.firstMerchantAmount}}</a-descriptions-item>
                  <a-descriptions-item label='商户应付'>{{order.originalMerchantAmount}}
                    <a-space style="margin-left: 20px">
                      <a @click='initialDeliver2(order)'>修改</a>
                    </a-space>
                  </a-descriptions-item>

                  <a-descriptions-item label='积分抵扣'>{{order.merchantDiscount}}</a-descriptions-item>
                  <a-descriptions-item v-if="order.merchantMember && order.merchantMember!=order.member" label='转账优惠'>{{order.bankTransDiscount}}</a-descriptions-item>
                  <a-descriptions-item v-else></a-descriptions-item>
                  <a-descriptions-item label='商户实付'><span class="color-red">{{order.merchantAmount}}</span></a-descriptions-item>

                </template>




            <a-descriptions-item label='商户支付方式'>{{order.merchantPaymentMethodName}}</a-descriptions-item>
            <a-descriptions-item label='商户支付时间'>{{order.merchantActualPaymentTime}}</a-descriptions-item>
<!--            merchantPaymentTime-->
            <a-descriptions-item label='所在地区'>{{order.area}}</a-descriptions-item>

<!--            <a-descriptions-item label='核算业绩' :span="4">{{order.checkPerformance}}</a-descriptions-item>-->
          </template>


        <template v-if="order.isShowThree">
            <a-descriptions-item label='总销'>{{order.wealthQuotaMemberName}}</a-descriptions-item>
            <a-descriptions-item label='付款人姓名'>{{order.fuZeRen}}</a-descriptions-item>
            <a-descriptions-item label='转账凭证'>
              <img v-for="(item, index) in order.merchantPaymentProof" :key="index" :src='item' style="margin-right: 20px;" width='100px' height="100px" :preview="true"/>
            </a-descriptions-item>
            <a-descriptions-item label='转账方式'>{{order.bankTransBuyerName?'他人代付':'本人付'}}</a-descriptions-item>
        </template>

      </a-descriptions>

      <a-descriptions title="发货信息" v-if="order.bussType == 'draw'">
        <a-descriptions-item label='订单商品' :span="3">{{order.orderProductionDesc}}</a-descriptions-item>
        <a-descriptions-item label='收货方式'>自提现货</a-descriptions-item>
        <a-descriptions-item label='提货人'>{{order.drawName}}</a-descriptions-item>
        <a-descriptions-item label='提货人手机号'>{{order.drawMobile}}</a-descriptions-item>
      </a-descriptions>
      <a-descriptions title="发货信息" v-else>
        <a-descriptions-item label='订单商品' :span="3">{{order.orderProductionDesc}}</a-descriptions-item>
        <a-descriptions-item label='收货方式'>物流发货</a-descriptions-item>
        <a-descriptions-item label='联系电话'>{{order.addressUserMobile}}</a-descriptions-item>
        <a-descriptions-item label='详细地址'>{{ order.address }}</a-descriptions-item>
      </a-descriptions>
    </a-card>
    <a-card :bordered='false' title="待付列表">
      <s-table ref='table' rowKey='id' size='default' :columns='columns' :data='loadData' :rowClassName="getRowClassName">
        <span slot='id' slot-scope='text,record'>
          <template>
              <a @click='handleChangeOrder(record)'>{{ text }}</a>
          </template>
        </span>

<!--        <span slot='originalMerchantAmount' slot-scope='text,record'>-->
<!--          <template>-->
<!--              <a @click='handleChangeOrder(record)'>{{ text }}</a>-->
<!--          </template>-->
<!--        </span>-->

      </s-table>
    </a-card>
    <a-card :bordered='false' title="购销记录" style='margin-top: 20px'>
      <a-descriptions :column="4">
        <a-descriptions-item label='单量'>{{merchantGxOrderCount}}单</a-descriptions-item>
        <a-descriptions-item label='金额'>{{merchantGxOrderAmount}}元</a-descriptions-item>
<!--        <a-descriptions-item label='商品折扣'>{{profile.discountDes}}</a-descriptions-item>-->
        <a-descriptions-item label='现金折扣'>{{profile.cashBalance}}</a-descriptions-item>
      </a-descriptions>
      <a-form-model ref='form' :model='queryParam' layout='inline' style="margin-bottom: 20px;">
        <a-form-model-item prop='start'>
          <a-range-picker
            v-model="dateArr"
            :format="[formatType, formatType]"
            :show-time="{
              hideDisabledOptions: true,
            }"
            :disabled-date="disabledDate"
            @change="dateChange"
          />
        </a-form-model-item>
        <a-form-model-item prop='spreaderMobile'>
          <a-input v-model='queryParam1.spreaderMobile' style="width: 300px" placeholder="推荐人手机号" />
        </a-form-model-item>
        <a-form-model-item>
          <a-button type='primary' @click='handleSubmit'>
            查询
          </a-button>
        </a-form-model-item>
        <a-form-model-item>
          <a-button @click='handleReset'>
            重置
          </a-button>
        </a-form-model-item>
        <!-- <a-form-model-item v-if="profile.type == 'president'">
          <a-button type='primary' @click='handleDetail'>
            详情
          </a-button>
        </a-form-model-item> -->
      </a-form-model>
      <s-table ref='table1' rowKey='id' size='default' :columns='columns1' :scroll='{ x: 1000}' :data='loadData1'>
        <span slot='orderId' slot-scope='text, record'>
          <template>
            <span :style="{color: orderColor(record)}">{{text}}</span>
          </template>
        </span>
        <span slot='id' slot-scope='text,record'>
          <template>
              {{record.bussType == "draw" ? "自提现货" : text}}
          </template>
        </span>
        <span slot='spreaderMobile' slot-scope='text,record'>
          <template>
            <span v-for="(item, index) in getSpreaderMobileList(record.spreaderMobile, record.spreaderOrderType, record.spreaderType)" :key="index" :style="{color : item.color}">{{item.text}}</span>
          </template>
        </span>
      </s-table>
    </a-card>

    <a-card :bordered='false' style='margin-top: 20px'>
      <span slot='title'>
        <span>现金折扣记录</span>
          <a-input disabled v-model='profile.cashBalance' style="width: 130px;margin-left: 20px;" />
          <a-button style="margin-left: 20px;" type='primary' @click='initialDeliver4(profile)'>
            修改
          </a-button>
        <a-button style="margin-left: 20px;" @click='initialDeliver10(profile)' type='primary'>
          获得
        </a-button>

        <a-button style="margin-left: 20px;" type='primary' @click='initialDeliver9(profile)'>
          转让
        </a-button>
      </span>
      <span slot='extra' @click="initialDeliver6">
        <img style="width: 30px;" v-show="!showCashRecord" :src="arrowDown">
        <img style="width: 30px;" v-show="showCashRecord" :src="arrowUp">
      </span>
      <s-table v-if="showCashRecord" ref='table2' rowKey='id' size='default' :columns='columns2' :scroll='{ x: 1000}' :data='loadData2' :showPagination='false' :pageSize='30'>
        <span slot='recordType' slot-scope='text'>
          <template>
            {{text == 1 ? "使用" : text == 2 ? "修改" : text == 3 ? "奖励" : ""}}
          </template>
        </span>
        <span slot='orderId' slot-scope='text, record'>
          <template>
<!--            {{record.businessName==="修改"||record.businessName==="现金补贴"?record.opUsername:record.orderId}}-->
            <!--            {{ record.transferFromUserName || record.transferToUserName || text }}-->
            {{showCaseText(record)}}
          </template>
        </span>
      </s-table>
    </a-card>

    <a-modal title='确认收款' :destroyOnClose='true' :maskClosable='true'
             :visible='deliver1.visible' @cancel='()=>this.deliver1.visible=false'
             ok-text='确认' cancel-text='取消' @ok='handleDeliver1' :confirmLoading='deliver1.loading'>
      <a-form-model ref='form1' :model='deliver1.form' :labelCol='{ span: 6 }' :wrapperCol='{ span: 18 }'>
        <a-form-model-item label='订单号' prop='orderNo'>
          {{deliver1.form.orderNo}}
        </a-form-model-item>
        <a-form-model-item label='总销' prop='merchantAmount'>
          {{deliver1.form.totalSall}}
        </a-form-model-item>
        <a-form-model-item label='商户名称' prop='merchantName'>
          <a-input disabled v-model='deliver1.form.merchantName' />
        </a-form-model-item>
        <a-form-model-item label='应收金额' prop='originalMerchantAmount'>
              <a-input v-model='deliver1.form.originalMerchantAmount' @change="changeAmount"/>
        </a-form-model-item>
<!--        <a-form-model-item label='商品折扣' prop='useWealthQuota'>-->
<!--          <a-input disabled v-model='deliver1.form.useWealthQuota' />-->
<!--        </a-form-model-item>-->
        <a-form-model-item label='剩余可用积分' prop='merchantDiscount'>
          <template>
            <a-input disabled v-model='deliver1.form.merchantDiscount' />
<!--              <a-checkbox style="float: right;" @change="handleUseChange" v-model='deliver1.form.isUseds'> style="width: 70%;"-->
<!--              不使用-->
<!--            </a-checkbox>-->
          </template>
        </a-form-model-item>

        <a-form-model-item label=' ' :colon="false">
            <a-radio-group v-model='deliver1.form.isUseds' @change="handleUseChange" :disabled="deliver1.form.orderDiscount==0 && deliver1.form.merchantPaymentMethod != ''">
              <a-radio value='0'>使用</a-radio>
              <a-radio value='1'>不使用</a-radio>
            </a-radio-group>
        </a-form-model-item>

        <a-form-model-item label='使用可用积分' prop='userCashBalance' v-if="deliver1.form.isUseds==='0' && !deliver1.form.orderDiscount && deliver1.form.merchantPaymentMethod=='' ">
          <a-input :disabled="!deliver1.form.canModUsesCashBalance" v-model='deliver1.form.userCashBalance' @change="handleChangeCashBalance" />
        </a-form-model-item>

        <template v-if="deliver1.form.orderDiscount">
          <a-form-model-item label='订单已用折扣' prop='orderDiscount' v-if="deliver1.form.isUseds==='0'">
            <a-input disabled  v-model='deliver1.form.orderDiscount' />
          </a-form-model-item>
        </template>

        <a-form-model-item label='实收金额' prop='merchantAmount'>
          <a-input disabled v-model='deliver1.form.merchantAmount' :class="font_class"/>
        </a-form-model-item>
        <a-form-model-item label=' ' prop='payMethod' :colon="false">
          <!--           <a-radio-group name="radioGroup" v-model="deliver1.form.payMethod">-->
          <!--            <a-radio value="trans">-->
          <!--              转账-->
          <!--            </a-radio>-->
          <!--            <a-radio value="cash">-->
          <!--              现金-->
          <!--            </a-radio>-->
          <!--          </a-radio-group>-->
          <a-checkbox style="float: right;" @change="(e) => deliver1.form.checkStatus = e.target.checked ? 1 : 0">
            待核销
          </a-checkbox>

          <!--          <a-checkbox v-if="this.isDingHuoDan" style="float: right;margin-right: 30px;" @change="handleDingHuoChange">-->
          <!--              订货单-->
          <!--          </a-checkbox>-->

        </a-form-model-item>
      </a-form-model>
    </a-modal>

    <a-modal title='折扣修改' :destroyOnClose='true' :maskClosable='true' :visible='deliver2.visible' @cancel='()=>this.deliver2.visible=false'>
      <template slot="footer">
        <a-button key="back" @click="()=>this.deliver2.visible=false">
          取消
        </a-button>
        <a-button key="submit1" type="primary" :loading="deliver2.loading" @click="handleDeliver2(1)">
          保存
        </a-button>
        <!--        <a-button key="submit2" type="primary" :loading="deliver2.loading" @click="handleDeliver2(2)">-->
        <!--          保存并收款-->
        <!--        </a-button>-->
      </template>
      <a-form-model ref='form2' :model='deliver2.form' :rules='deliver2.rules' :labelCol='{ span: 6 }' :wrapperCol='{ span: 18 }'>
        <!--        <a-form-model-item label='经销商名称' prop='merchantName'>-->
        <!--          <a-input disabled v-model='deliver2.form.merchantName' />-->
        <!--        </a-form-model-item>-->
        <a-form-model-item label='应收金额' prop='originalMerchantAmount'>
          <a-input v-model='deliver2.form.originalMerchantAmount' @change="changeMerchantAmount" />
        </a-form-model-item>
      </a-form-model>
    </a-modal>


    <a-modal title='现金折扣修改' :destroyOnClose='true' :maskClosable='true'
             :visible='deliver4.visible' @cancel='()=>this.deliver4.visible=false'
             ok-text='确认' cancel-text='取消' @ok='handleDeliver4' :confirmLoading='deliver4.loading'>
      <a-form-model ref='form4' :model='deliver4.form' :rules='deliver4.rules' :labelCol='{ span: 6 }' :wrapperCol='{ span: 18 }'>
        <a-form-model-item label='现金折扣' prop='cashBalance'>
          <a-input v-model='deliver4.form.cashBalance' />
        </a-form-model-item>
        <a-form-model-item label='备注内容' prop='remark'>
          <a-input v-model='deliver4.form.remark' />
        </a-form-model-item>
      </a-form-model>
    </a-modal>

    <a-modal title='商品折扣修改备注' :footer="null" :destroyOnClose='true' :maskClosable='true' :visible='deliver5.visible' @cancel='()=>this.deliver5.visible=false' :width="1000">
      <s-table id="table5" ref='table5' rowKey='id' size='default' :columns='columns5' :scroll='{ x: 1000}' :data='loadData5'>
      </s-table>
    </a-modal>

    <a-modal title='现金折扣修改备注' :footer="null" :destroyOnClose='true' :maskClosable='true' :visible='deliver6.visible' @cancel='()=>this.deliver6.visible=false' :width="1000">
      <s-table id="table6" ref='table6' rowKey='id' size='default' :columns='columns6' :scroll='{ x: 1000}' :data='loadData6'>
      </s-table>
    </a-modal>


    <a-modal title='现金折扣转让' :destroyOnClose='true' :maskClosable='true'
             :visible='deliver9.visible' @cancel='()=>this.deliver9.visible=false'
             ok-text='确认' cancel-text='取消' @ok='handleDeliver9' :confirmLoading='deliver9.loading'>
      <a-form-model ref='form9' :model='deliver9.form' :rules='deliver9.rules' :labelCol='{ span: 6 }' :wrapperCol='{ span: 18 }'>
        <a-form-model-item label='变动类型' prop='type'>
          <a-select v-model="deliver9.form.type" placeholder="变动类型">
            <a-select-option :value="undefined">
              变动类型
            </a-select-option>
            <a-select-option :value='5'>
              转让
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label='转让人' prop='zhuanRan'>
          <a-input v-model='deliver9.form.zhuanRan'  @change="onChangeMobileCash" style="width: 60%;margin-right: 15px;"/>
          <a-input v-model='deliver9.form.cash' disabled style="width: 34%"/>
          <a-input v-model='deliver9.form.id' style="display:none"/>
        </a-form-model-item>
        <a-form-model-item label='转让金额' prop='quantity'>
          <a-input v-model='deliver9.form.quantity' />
        </a-form-model-item>
        <a-form-model-item label='受让人' prop='mobile'>
          <a-input v-model='deliver9.form.mobile' @change="getShouRanInfoCash"/>
<!--          <a-input v-model='deliver9.form.toMember' style="display:none"/>-->
        </a-form-model-item>
      </a-form-model>
    </a-modal>

    <a-modal title='现金折扣获得' :destroyOnClose='true' :maskClosable='true'
             :visible='deliver10.visible' @cancel='()=>this.deliver10.visible=false'
             ok-text='确认' cancel-text='取消' @ok='handleDeliver10' :confirmLoading='deliver10.loading'>
      <a-form-model ref='form10' :model='deliver10.form' :rules='deliver10.rules' :labelCol='{ span: 6 }' :wrapperCol='{ span: 18 }'>
        <a-form-model-item label='现金折扣' prop='cashBalance'>
          <a-input v-model='deliver10.form.cashBalance' />
        </a-form-model-item>

        <a-form-model-item label='备注内容' prop='remark'>
          <a-input v-model='deliver10.form.remark' />
        </a-form-model-item>
      </a-form-model>
    </a-modal>

  </page-header-wrapper>
</template>

<script>
import {Ellipsis, STable} from '@/components'
import {addMerchantCash, getMember, transferMerchantCash, updateMerchantCash} from '@/api/member'
import {
  deleteMemberOrder,
  getMemberOrder,
  getMobileMember,
  payMemberOrder,
  queryMerchantCash,
  queryMerchantWaitOrder,
  updateMemberOrder,
  updateMerchantQuantity
} from '@/api/member-order'
import {queryQuantityOrder, queryQuantityUpdate} from '@/api/quantity'
import {getFuZeRen, getSpreaderMobileList, showOrderType} from '@/utils/util'
import moment from 'moment'

const columns = [
  {
    width: 150,
    title: '订单号',
    dataIndex: 'id',
    scopedSlots: { customRender: 'id' }
  },
  {
    width: 200,
    title: '下单时间',
    dataIndex: 'createTime',
    scopedSlots: { customRender: 'createTime' }
  },
  {
    width: 150,
    title: '应付金额',
    dataIndex: 'originalMerchantAmount',
    scopedSlots: { customRender: 'originalMerchantAmount' }
  },
  {
    width: 150,
    title: '收货人',
    dataIndex: 'addressUserName',
    scopedSlots: { customRender: 'addressUserName' }
  },
  {
    width: 150,
    title: '收货人手机号',
    dataIndex: 'addressUserMobile',
    scopedSlots: { customRender: 'addressUserMobile' }
  },
]

const columns1 = [
  {
    width: 150,
    title: '订单号',
    dataIndex: 'orderId',
    scopedSlots: { customRender: 'orderId' }
  },
  {
    width: 150,
    title: '原始应付',
    dataIndex: 'originalMerchantAmount',
    scopedSlots: { customRender: 'originalMerchantAmount' }
  },
  {
    width: 150,
    title: '现金折扣',
    dataIndex: 'merchantDiscount',
    scopedSlots: { customRender: 'merchantDiscount' }
  },
  {
    width: 150,
    title: '实付金额',
    dataIndex: 'orderAmount',
    scopedSlots: { customRender: 'orderAmount' }
  },
  {
    width: 200,
    title: '下单时间',
    dataIndex: 'orderCreateTime',
    scopedSlots: { customRender: 'orderCreateTime' }
  },
  {
    width: 200,
    title: '推荐人手机号',
    dataIndex: 'spreaderMobile',
    scopedSlots: { customRender: 'spreaderMobile' }
  },
  {
    width: 150,
    title: '物流单号',
    dataIndex: 'logisticsNo',
    scopedSlots: { customRender: 'logisticsNo' }
  },
]

const columns2 = [
  {
    width: 150,
    title: '变动类型',
    dataIndex: 'businessName',
    scopedSlots: { customRender: 'businessName' }
  },
  {
    width: 150,
    title: '变动内容',
    dataIndex: 'quantity',
    scopedSlots: { customRender: 'quantity' }
  },
  {
    width: 150,
    title: '变动后现金折扣',
    dataIndex: 'afterBalance',
    scopedSlots: { customRender: 'afterBalance' }
  },
  {
    width: 150,
    title: '发生订单号',
    dataIndex: 'orderId',
    scopedSlots: { customRender: 'orderId' }
  },
  {
    width: 200,
    title: '变动时间',
    dataIndex: 'updateTime',
    scopedSlots: { customRender: 'updateTime' }
  },
  {
    width: 200,
    title: '备注',
    dataIndex: 'des',
    scopedSlots: { customRender: 'des' }
  },
  {
    width: 150,
    title: '订单所属商户',
    dataIndex: 'merchantAuthCode',
    scopedSlots: { customRender: 'merchantAuthCode' }
  }
]

const columns3 = [
  {
    width: 150,
    title: '变动类型',
    dataIndex: 'businessName',
    scopedSlots: { customRender: 'businessName' }
  },
  {
    width: 150,
    title: '变动内容',
    dataIndex: 'businessContent',
    scopedSlots: { customRender: 'businessContent' }
  },
  {
    width: 150,
    title: '变动后商品折扣',
    dataIndex: 'afterAmountDes',
    scopedSlots: { customRender: 'afterAmountDes' }
  },
  {
    width: 150,
    title: '发生订单号',
    dataIndex: 'orderId',
    scopedSlots: { customRender: 'orderId' }
  },
  {
    width: 200,
    title: '变动时间',
    dataIndex: 'createTime',
    scopedSlots: { customRender: 'createTime' }
  },
  {
    width: 200,
    title: '备注',
    dataIndex: 'orderTypeName',
    scopedSlots: { customRender: 'orderTypeName' }
  },
  {
    width: 150,
    title: '订单所属商户',
    dataIndex: 'merchantAuthCode',
    scopedSlots: { customRender: 'merchantAuthCode' }
  }
]

const columns5 = [
  {
    width: 150,
    title: '备注内容',
    dataIndex: 'orderTypeName',
    scopedSlots: { customRender: 'orderTypeName' }
  },
  {
    width: 200,
    title: '备注时间',
    dataIndex: 'createTime',
    scopedSlots: { customRender: 'createTime' }
  },
  {
    width: 150,
    title: '操作人',
    dataIndex: 'opUsername',
    scopedSlots: { customRender: 'opUsername' }
  },
]

const columns6 = [
  {
    width: 150,
    title: '备注内容',
    dataIndex: 'des',
    scopedSlots: { customRender: 'des' }
  },
  {
    width: 200,
    title: '备注时间',
    dataIndex: 'createTime',
    scopedSlots: { customRender: 'createTime' }
  },
  {
    width: 150,
    title: '操作人',
    dataIndex: 'opUsername',
    scopedSlots: { customRender: 'opUsername' }
  },
]

const formatType = 'YYYY-MM-DD';
const baseStart = moment().startOf('month').format(formatType);
const baseEnd = moment().format(formatType);

export default {
  name: 'OrderEdit',
  data() {
    return {
      // orderEnd:'',//定义订单尾号
      // orderType:'',//订单类型1：用户下单，2：商户下单
      font_class:"",
      font_class_edit:"",
      arrowDown: require('@/assets/arrow-down.png'),
      arrowUp: require('@/assets/arrow-up.png'),
      columns,
      columns1,
      columns2,
      columns3,
      columns5,
      columns6,
      formatType,
      isDingHuoDan:false,
      order: {},
      profile: {},
      merchantGxOrderCount: 0,
      merchantGxOrderAmount: 0,
      tempDiscountUseValue:0,
      tempMerchantAmount:0,
      queryParam: {},
      loadData: parameter => {
        let queryParam = this.queryParam;
        queryParam.member = this.id;
        return queryMerchantWaitOrder(Object.assign(parameter, queryParam))
          .then(datum => {
            return datum
          })
      },
      dateArr: [baseStart, baseEnd],
      queryParam1: {
        // merchantOrderStatus: 2,
        start: baseStart,
        end: baseEnd,
      },
      loadData1: parameter => {
        let queryParam = this.queryParam1;
        queryParam.id = this.id;
        parameter.pageNum = parameter.current;
        parameter.pageSize = parameter.size;
        return queryQuantityOrder(Object.assign(parameter, queryParam))
          .then(datum => {
            this.merchantGxOrderCount = datum.total;
            this.merchantGxOrderAmount = datum.records.length > 0 ? datum.records[0].merchantGxOrderAmount : 0;
            return datum
          })
      },
      showCashRecord: true,
      loadData2: parameter => {
        let queryParam = {};
        queryParam.member = this.id;
        return queryMerchantCash(Object.assign(parameter, queryParam))
          .then(datum => {
            // debugger;
            return datum
          })
      },
      showQuotaRecord: true,
      loadData3: parameter => {
        parameter.pageNum = parameter.current;
        parameter.pageSize = parameter.size;
        let queryParam = {};
        queryParam.id = this.id;
        return queryQuantityUpdate(Object.assign(parameter, queryParam))
          .then(datum => {
            // debugger;
            return datum
          })
      },
      loadData5: parameter => {
        parameter.pageNum = parameter.current;
        parameter.pageSize = parameter.size;
        let queryParam = {};
        queryParam.id = this.id;
        queryParam.business = 3;
        return queryQuantityUpdate(Object.assign(parameter, queryParam))
          .then(datum => {
            return datum
          })
      },
      loadData6: parameter => {
        let queryParam = {};
        queryParam.member = this.id;
        queryParam.recordType = 2;
        return queryMerchantCash(Object.assign(parameter, queryParam))
          .then(datum => {
            return datum
          })
      },
      loading: false,
      deliver1: { visible: false, form: {}, loading: false },
      deliver2: { visible: false, form: {}, loading: false, rules: {
          originalMerchantAmount: [{ required: true, message: '请填写应收金额', trigger: 'change' }]
        }},
      deliver3: { visible: false, form: {}, loading: false, rules: {
        discountDes: [
          { required: true, message: '请填写商品折扣', trigger: 'change' },
          {
            validator: (rule, value, callback) => {
              let isAdopt = false;
              let discountReg = [
                /^-?3\d+$/,
                /^-?4\d+$/,
                /^-?5\d+$/,
                /^-?7\d+$/,
                /^-?3\d+\/-?4\d+$/,
                /^-?3\d+\/-?5\d+$/,
                /^-?3\d+\/-?7\d+$/,
                /^-?4\d+\/-?5\d+$/,
                /^-?4\d+\/-?7\d+$/,
                /^-?5\d+\/-?7\d+$/,
                /^-?3\d+\/-?4\d+\/-?5\d+$/,
                /^-?3\d+\/-?4\d+\/-?7\d+$/,
                /^-?3\d+\/-?5\d+\/-?7\d+$/,
                /^-?4\d+\/-?5\d+\/-?7\d+$/,
                /^-?3\d+\/-?4\d+\/-?5\d+\/-?7\d+$/];
              for (const item of discountReg) {
                if (item.test(value)) {
                  isAdopt = true;
                }
              }
              if (!isAdopt) {
                callback(new Error('商品折扣的格式必须为3X,4X,5X,3X/4X,3X/5X,3X/7X,4X/5X,4X/7X,5X/7X,3X/4X/5X,3X/4X/7X,3X/5X/7X,4X/5X/7X,3X/4X/5X/7/X，其中X必须为正整数'));
              } else {
                callback();
              }
            }, 
            trigger: 'change'
          },
        ]
      }},
      deliver4: { visible: false, form: {}, loading: false, rules: {
          cashBalance: [
            { required: true, message: '请填写商品折扣', trigger: 'change' },
          ]
        }},
      deliver5: { visible: false, form: {}, loading: false},
      deliver6: { visible: false, form: {}, loading: false},
      deliver7: { visible: false, form: {}, loading: false,rules:{
          type: [
            { required: true, message: '请选择变动类型', trigger: 'change' },
          ],
          zhuanRan:[
            { required: true, message: '请填写转让人', trigger: 'change' },
          ],
          shouRan:[
            { required: true, message: '请填写受让人', trigger: 'change' },
          ],
          ming:[
            { required: true, message: '请选择转让名额', trigger: 'change' },
          ],
          amount:[
            { required: true, message: '请输入数量', trigger: 'change' },
          ],
          zheKou:[
            { required: true, message: '缺失折扣', trigger: 'change' },
          ]
        }},
      deliver8: { visible: false, form: {}, loading: false,rules:{
          quotaType: [
            { required: true, message: '请选择变动类型', trigger: 'change' },
          ],
          amount:[
            { required: true, message: '请输入数量', trigger: 'change' },
          ],
          zheKou:[
            { required: true, message: '缺失折扣', trigger: 'change' },
          ]
        }},
      deliver9: { visible: false,
        form: {
          type:'5',
        }, loading: false,rules:{
          type: [
            { required: true, message: '请选择变动类型', trigger: 'change' },
          ],
          zhuanRan:[
            { required: true, message: '请填写转让人', trigger: 'change' },
          ],
          mobile:[
            { required: true, message: '请填写受让人', trigger: 'change' },
          ],
          quantity:[
            { required: true, message: '请输入现金折扣', trigger: 'change' },
          ]
        }},
      deliver10: { visible: false, form: {}, loading: false,rules:{
          cashBalance:[
            { required: true, message: '请输入转让金额', trigger: 'change' },
          ]
        }},
    }
  },
  components: { STable, Ellipsis},
  computed: {
    // id () {
    //   return this.$route.query.id
    // }
  },
  // watch:{
  //   id () {
  //     this.handleInitial();
  //   }
  //
  // },
  activated(){
    this.id = this.$route.query.id;
    this.handleInitial()
  },
  created() {
    this.id = this.$route.query.id;
    this.handleInitial()

  },
  methods: {
    showText(record){
      // debugger;
      let text = "";
      if(record.business === 9||record.business === 3||
        record.business === 10||record.business === 11||record.business === 12){
        text = record.opUsername;
      }else if(record.business === 6){
        text = record.transferFromUserName+"转";
      }else if(record.business === 5){
        text = record.transferToUserName+"得";
      }else{
        text = record.orderId;
      }
      // text = text +"---"+ record.business+'---'+record.orderId
      return text;
    },
    showCaseText(record){
      // debugger;
      let text = "";
      if(record.recordType===2|| record.recordType === 9||record.recordType==14){
        text = record.opUsername;
      }else if(record.recordType === 6){
        text = record.transferFromUserName+"转";
      }else if(record.recordType === 5){
        text = record.transferToUserName+"得";
      }else{
        text = record.orderId;
      }
      // debugger;
      // text = text + record;
      return text;
    },
    getSpreaderMobileList,
    getRowClassName(record) {
      if (record.activityId) {
        return 'color-red';
      }
    },
    orderColor(record) {
      if (record.used == 1) {
        return '#1890ff'
      } else {
        let nowDate = new Date();
        let expireDate = new Date(record.expire);
        if (expireDate.getTime() < nowDate.getTime()) {
          return '#E62734'
        }
      }
      return ''
    },
    changeMerchantAmount() {
      let originalMerchantAmount = parseFloat(this.deliver2.form.originalMerchantAmount) || 0;
      let cashBalance = parseFloat(this.deliver2.form.cashBalance) || 0;
      if (cashBalance > 0 && originalMerchantAmount > 0) {
        if (originalMerchantAmount >= cashBalance) {
          this.deliver2.form.merchantDiscount = "-" + cashBalance;
          this.deliver2.form.merchantAmount = parseFloat((originalMerchantAmount - cashBalance).toFixed(2)) ;
        } else {
          this.deliver2.form.merchantDiscount = "-" + originalMerchantAmount
          this.deliver2.form.merchantAmount = 0;
        }
      } else {
        this.deliver2.form.merchantDiscount = "";
        this.deliver2.form.merchantAmount = originalMerchantAmount;
      }
    },
    handleDeliver10() {
      this.$refs.form10.validate((result) => {
        this.deliver10.loading = true;
        let param = Object.assign(this.deliver10.form);

        addMerchantCash(param).then(_result => {
          this.$message.success('操作成功');
          this.deliver10.visible = false
          this.deliver10.loading = false;
          this.handleInitial(this.id);
        }).catch(() => {
          this.deliver10.loading = false;
          this.deliver10.visible = false
        })
      })

    },
    handleDeliver9() {
      this.$refs.form9.validate((result) => {
        if (result) {
          if(this.deliver9.form.zhuanRan === this.deliver9.form.mobile){
            //转让和受让人手机不允许相同
            this.$message.warning('转让人与受让人不能为同一个人！');
          }else{
            this.deliver9.loading = true;
            let param = Object.assign(this.deliver9.form);
            // debugger;
            transferMerchantCash(param).then(_result => {
              this.$message.success('操作成功');
              this.deliver9.visible = false
              this.deliver9.loading = false;
              this.handleInitial(this.id);
            }).catch(() => {
              this.deliver9.loading = false;
              this.deliver9.visible = false
            })
          }
        }
      })
    },
    // handleDeliver8() {
    //   this.$refs.form8.validate((result) => {
    //     this.deliver8.loading = true;
    //     let param = Object.assign(this.deliver8.form);
    //     // debugger;
    //     addMerchant(param).then(_result => {
    //       this.$message.success('操作成功');
    //       this.deliver8.visible = false
    //       this.deliver8.loading = false;
    //       this.handleInitial(this.id);
    //     }).catch(() => {
    //       this.deliver8.loading = false;
    //       this.deliver8.visible = false
    //     })
    //   })
    //
    // },
    // handleDeliver7() {
    //   this.$refs.form7.validate((result) => {
    //     if (result) {
    //       if(this.deliver7.form.fromMember === this.deliver7.form.toMember){
    //         //转让和受让人手机不允许相同
    //         this.$message.warning('转让人与受让人不能为同一个人！');
    //       }else{
    //         this.deliver7.loading = true;
    //         let param = Object.assign(this.deliver7.form);
    //         // debugger;
    //         transferMerchant(param).then(_result => {
    //           this.$message.success('操作成功');
    //           this.deliver7.visible = false
    //           this.deliver7.loading = false;
    //           this.handleInitial(this.id);
    //         }).catch(() => {
    //           this.deliver7.loading = false;
    //           this.deliver7.visible = false
    //         })
    //       }
    //     }
    //   })
    // },
    initialDeliver10(record) {
      this.deliver10.form = { id: this.id}
      this.deliver10.visible = true
    },
    initialDeliver9(record) {
      this.deliver9.form = { type: 5,zhuanRan:this.profile.mobile}
      this.deliver9.visible = true
      //自动搜索本商户
      this.onChangeMobileCash();
    },
    // initialDeliver7(record) {
    //   this.deliver7.form = { }
    //   this.deliver7.visible = true
    // },
    initialDeliver5() {
      this.deliver5.loading = false
      this.deliver5.visible = true
      if (this.$refs.table5) {
        this.$refs.table5.refresh(true)
      }
    },
    initialDeliver6() {
      this.deliver6.loading = false
      this.deliver6.visible = true
      if (this.$refs.table6) {
        this.$refs.table.refresh(true)
      }
    },
    initialDeliver4(record) {
      this.deliver4.form = { id: this.id, cashBalance: record.cashBalance, remark: ""}
      this.deliver4.visible = true
    },
    handleDeliver4() {
      this.$refs.form4.validate((result) => {
        if (result) {
          this.deliver4.loading = true;
          let param = this.deliver4.form;
          debugger;
          updateMerchantCash(param).then(_result => {
            this.$message.success('操作成功')
            this.handleInitial(this.id);
            this.deliver4.visible = false
            this.deliver4.loading = false;
            this.$refs.table2.refresh(true)
          }).catch(() => {
            this.deliver4.loading = false;
          })
        }
      })
    },
    initialDeliver3(record) {
      this.deliver3.form = { id: this.id, discountDes: record.discountDes, remark: ""}
      this.deliver3.visible = true
    },
    handleDeliver3() {
      this.$refs.form3.validate((result) => {
        if (result) {
          let discountDes = this.deliver3.form.discountDes;
          let isAdopt = false;
          let discountReg = [
            /^-?3\d+$/,
            /^-?4\d+$/,
            /^-?5\d+$/,
            /^-?7\d+$/,
            /^-?3\d+\/-?4\d+$/,
            /^-?3\d+\/-?5\d+$/,
            /^-?3\d+\/-?7\d+$/,
            /^-?4\d+\/-?5\d+$/,
            /^-?4\d+\/-?7\d+$/,
            /^-?5\d+\/-?7\d+$/,
            /^-?3\d+\/-?4\d+\/-?5\d+$/,
            /^-?3\d+\/-?4\d+\/-?7\d+$/,
            /^-?3\d+\/-?5\d+\/-?7\d+$/,
            /^-?4\d+\/-?5\d+\/-?7\d+$/,
            /^-?3\d+\/-?4\d+\/-?5\d+\/-?7\d+$/];
          for (const item of discountReg) {
            if (item.test(discountDes)) {
              isAdopt = true;
            }
          }
          if (!isAdopt) {
            this.$message.warning('商品折扣的格式必须为3X,4X,5X,3X/4X,3X/5X,3X/7X,4X/5X,4X/7X,5X/7X,3X/4X/5X,3X/4X/7X,3X/5X/7X,4X/5X/7X,3X/4X/5X/7/X，其中X必须为正整数');
            return;
          }
          this.deliver3.loading = true;
          updateMerchantQuantity(this.deliver3.form).then(_result => {
            this.$message.success('操作成功')
            this.handleInitial(this.id);
            this.deliver3.visible = false
            this.deliver3.loading = false;
            this.$refs.table3.refresh(true)
          }).catch(() => {
            this.deliver3.loading = false;
          })
        }
      })
    },
    initialDeliver2(record) {
      const cashBalance = record.merchantPaymentMethod ? record.merchantDiscount : this.profile.cashBalance;
      this.deliver2.form = {
        id: record.id,
        payMethod: "trans",
        // merchantName: record.merchantName,
        originalMerchantAmount: record.originalMerchantAmount,
        // useWealthQuota: record.useWealthQuota == 1 ? "-31" : record.useWealthQuota == 2 ? "-51" : "",
        // merchantDiscount: record.merchantDiscount,
        // merchantAmount: record.merchantAmount,
        // orderType: record.orderType,
        // merchantPaymentMethod: record.merchantPaymentMethod,
        cashBalance: cashBalance
      }
      this.changeMerchantAmount();
      this.deliver2.visible = true
    },
    handleDeliver2(type) {
      if (this.deliver2.form.merchantPaymentMethod && this.deliver2.form.originalMerchantAmount < this.deliver2.form.cashBalance) {
        this.deliver2.form.originalMerchantAmount = this.deliver2.form.cashBalance;
        this.$message.warning('应收金额不能小于已支付的现金折扣');
        this.changeMerchantAmount();
        return;
      }
      this.$refs.form2.validate((result) => {
        if (result) {
          this.deliver2.loading = true;
          updateMemberOrder({
            id: this.deliver2.form.id,
            merchantAmount: this.deliver2.form.originalMerchantAmount,
            checkStatus: this.deliver2.form.checkStatus || 0,
          }).then(_result => {
            if (type === 2) {
              let params = Object.assign({id: this.deliver2.form.id, payMethod: this.deliver2.form.payMethod,checkStatus: this.deliver2.form.checkStatus || 0});
              // debugger;
              payMemberOrder(params).then(_result => {
                this.$message.success('操作成功')
                this.handleInitial(this.id);
                this.deliver2.visible = false
                this.$refs.table.refresh(true)
                this.$refs.table1.refresh(true)
                this.order = {}
                this.deliver2.loading = false;
              }).catch(() => {
                this.deliver2.loading = false;
              })
            } else {
              this.$message.success('操作成功')
              this.deliver2.visible = false
              this.handleChangeOrder(this.deliver2.form)
              this.deliver2.loading = false;
            }
          }).catch(() => {
            this.deliver2.loading = false;
          })
        }
      })
    },
    initialDeliver1(record) {
      let backRecord = record.operatorNickname=='后台录单';//是否为后台录单
      let originalMerchantAmount = parseFloat(record.originalMerchantAmount) || 0;//应收金额
      let cashBalance = parseFloat(this.profile.cashBalance);
      let tempDiscount = parseFloat(record.merchantDiscount);
      let merchantAmount = 0;
      let canModUsesCashBalance = false;//是否允许修改用户积分
      let isUseds = 1;//默认选择不允许使用
      let userCashBalance = 0;//显示实际使用积分
      if(backRecord){
        // 2、后台给用户和商户录单，录单后需确认收款。此场景可正常修改。
        isUseds = '1';
        canModUsesCashBalance = true;
        merchantAmount = originalMerchantAmount;
      }else{
        //1、商户小程序支付给公司时选择转账或扫码支付，提交到后台确认收款。此场景不能修改使用积分。
        //首先判断订单是否有折扣，有折扣就直接使用订单折扣，没有就开放给用户使用折扣
        if(tempDiscount>0){
          //订单有折扣
          if (originalMerchantAmount > tempDiscount) {
              merchantAmount = originalMerchantAmount - tempDiscount;
          } else {
              merchantAmount = 0;//等于直接抵扣
          }
          userCashBalance = tempDiscount;
          isUseds = '0';
          canModUsesCashBalance = false;
        }else{
          //订单没有折扣，开放给用户使用帐户积分，可以选择，但不能修改
          isUseds = '1';
          canModUsesCashBalance = true;
          merchantAmount = originalMerchantAmount;
        }
      }

      let merchantPaymentMethod = record.merchantPaymentMethod;

      this.deliver1.form = {
        orderNo:record.id,
        id: record.id,
        totalSall:record.wealthQuotaMemberName,//总销名称
        // payMethod: record.merchantPaymentMethod||record.paymentMethod,//||"bank_trans"
        merchantName: record.merchantName,
        merchantDiscount: cashBalance,//现金折扣
        originalMerchantAmount: originalMerchantAmount,
        tempOriginalMerchantAmount:originalMerchantAmount,
        merchantAmount: merchantAmount,//merchantAmount
        userCashBalance:0,//使用现金折扣初始化
        orderDiscount:userCashBalance,//订单原本折扣
        tempUserCashBalance:cashBalance,//帐户余额临时存放
        isUseds:isUseds,
        tempDiscount:tempDiscount,
        canModUsesCashBalance : canModUsesCashBalance,//是否允许使用
        merchantPaymentMethod:merchantPaymentMethod,//支付方式，这个为空才能允许使用折扣
      }
      this.tempDiscountUseValue = tempDiscount;
      this.deliver1.visible = true



      // let tempDiscount = 0;
      // let mPayM = record.merchantPaymentMethod;
      //
      // if(mPayM===undefined){
      //   if(cashBalance>originalMerchantAmount){
      //     tempDiscount = originalMerchantAmount;
      //   }else{
      //     tempDiscount=cashBalance;
      //   }
      // }else{
      //   //小程序下单
      //   tempDiscount = record.merchantDiscount;
      // }

      // let temp = merchantAmount
      // let useWq = record.useWealthQuota;
      // if(record.batchSelect===1){
      //   //批量--71
      //   merchantAmount = 0;
      //   tempDiscount = 0;
      // }else{
      //   merchantAmount = parseFloat(merchantAmount.toFixed(2));
      //   //不是批量，是否标记为订货单逻辑判断
      //   if(useWq===2){
      //     this.isDingHuoDan = true;
      //   }
      // }

      // let qType = "";
      // if(record.useWealthQuota == 1){
      //   qType = "-41"
      // }else if(record.useWealthQuota == 2){
      //   qType = "-51"
      // }else if(record.useWealthQuota == 3) {
      //   qType = "-31"
      // }else if(record.useWealthQuota == 4) {
      //   qType = "-71"
      // }else{
      //   qType ="";
      // }


      // debugger;
    },
    handleDeliver1() {
      this.$refs.form1.validate((result) => {
        if (result) {

          if(!this.deliver1.form.orderDiscount && this.deliver1.form.userCashBalance>this.deliver1.form.merchantDiscount){
            this.$message.error("不能大于剩余可用积分")
            return;
          }
          if(this.deliver1.form.isUseds=='0' && !this.deliver1.form.orderDiscount && !this.deliver1.form.userCashBalance){
            this.$message.error("使用积分不能为空")
            return;
          }

          let isUsed = this.deliver1.form.isUseds;

          this.deliver1.loading = true;
          let params = Object.assign({
            id: this.deliver1.form.id, payMethod: this.deliver1.form.payMethod,
            checkStatus: this.deliver1.form.checkStatus || 0,
            useCash:isUsed,
            originalMerchantAmount:this.deliver1.form.originalMerchantAmount,
          })


          if( isUsed =='0' && !this.deliver1.form.orderDiscount){
            //只有允许修改的时候才能传
            params.cashValue = this.deliver1.form.userCashBalance;
          }
          payMemberOrder(params).then(_result => {
            this.$message.success('操作成功')
            this.handleInitial(this.id);
            this.deliver1.visible = false
            this.deliver1.loading = false;
            this.order = {}

          }).catch(() => {
            this.deliver1.loading = false;
          })
        }
      })
    },
    handleInitial() {
      getMember({ id: this.id }).then(result => {
        this.profile = Object.assign({}, result)
      })
      if(this.$refs.table){
        this.$refs.table.refresh(true)
      }
      if(this.$refs.table1){
        this.$refs.table1.refresh(true)
      }
      if(this.$refs.table2){
        this.$refs.table2.refresh(true)
      }
      if(this.$refs.table3){
        this.$refs.table3.refresh(true)
      }
      //清空待付列表
      this.order = {};
    },
    handleChangeOrder(record) {
      console.log("*****************")
      getMemberOrder({ id: record.id }).then(result => {
        if (result.merchantPaymentProof) {
          result.merchantPaymentProof = result.merchantPaymentProof.split(",");
        } else {
          result.merchantPaymentProof = [];
        }

        result.fuZeRen = getFuZeRen(result);//获取负责人

        result = showOrderType(result);  //公共方法获取显示区域


        this.order = result
        this.$previewRefresh()
      })
    },
    handleDelete(record) {
      deleteMemberOrder({ id: record.id }).then(result => {
        this.$message.success('操作成功')
        this.order = {}
        this.$refs.table.refresh(true)
      })
    },
    disabledDate(current) {
      return current && current > moment().endOf('day');
    },
    dateChange() {
      this.queryParam1.start =  moment(this.dateArr[0]).format(this.formatType);
      this.queryParam1.end =  moment(this.dateArr[1]).format(this.formatType);
    },
    handleReset() {
      this.$refs.form.resetFields()
      this.$refs.table1.refresh(true)
      this.dateArr = [baseStart, baseEnd];
      this.queryParam1.start = baseStart;
      this.queryParam1.end = baseEnd;
    },
    handleSubmit() {
      this.$refs.table1.refresh(true)
    },
    handleDetail() {
      this.$router.push({ path: '/order/detail', query: { id: this.id } })
    },
    handleUseChange(e){
      // let a= this.deliver1.form.orderDiscount;
      if(this.deliver1.form.orderDiscount==0 && this.deliver1.form.merchantPaymentMethod != ''){
        this.$message.error("由于本订单类型属于扫描或者转账支付，用户已经付过款了，不能使用现金折扣");
        return;
      }

      let _form = this.deliver1.form;
      if(_form.isUseds=='1'){
        //勾选了不使用
        // this.tempDiscountUseValue = this.deliver1.form.merchantDiscount;
        this.deliver1.form.userCashBalance = 0;
        this.isChecked = 1;
      }else{
        //勾选了使用
        this.isChecked = 0;
        if(this.deliver1.form.tempDiscount>0){
          this.deliver1.form.userCashBalance = this.deliver1.form.tempDiscount;
        }else{
          this.deliver1.form.userCashBalance = this.deliver1.form.tempUserCashBalance;
        }
      }
      this.deliver1.form.merchantAmount = this.initValue();
    },
    changeAmount(e){
      const regex = /^[0-9]\d*$/;
      const value = event.target.value;
      if (!regex.test(value)) {
        debugger;
        this.deliver1.form.originalMerchantAmount = this.deliver1.form.tempOriginalMerchantAmount;
        let tempAmount = this.initValue() ;
        this.deliver1.form.merchantAmount =tempAmount;
        // this.deliver1.form.userCashBalance = this.deliver1.form.tempOriginalMerchantAmount;
        this.$message.info('只能输入正整数！');
        return;
      }

      let _form = this.deliver1.form;
      if(_form.isUseds=='1'){
        //勾选了不使用
        // this.tempDiscountUseValue = this.deliver1.form.merchantDiscount;
        this.deliver1.form.userCashBalance = 0;
      }else{
        //勾选了使用
        if(this.deliver1.form.tempDiscount>0){
          this.deliver1.form.userCashBalance = this.deliver1.form.tempDiscount;
        }else{
          this.deliver1.form.userCashBalance = this.deliver1.form.tempUserCashBalance;
        }
      }
      let totalAmount = this.initValue();
      this.deliver1.form.merchantAmount = totalAmount;
    },
    onChangeMobile() {
      if (this.deliver7.form.zhuanRan.length >= 11) {
        this.getMerchantInfo();
      }
    },
    onChangeMobileCash() {
      if (this.deliver9.form.zhuanRan.length >= 11) {
        getMobileMember({ phone: this.deliver9.form.zhuanRan }).then(result => {

          this.$set(this.deliver9.form, "cash", result.cashBalance);
          this.$set(this.deliver9.form, "id", result.id);
        })
      }
    },
    getShouRanInfoCash() {
      if (this.deliver9.form.mobile.length >= 11) {
        // getMobileMember({ phone: this.deliver9.form.shouRan }).then(result => {
        //   // debugger;
        //   this.$set(this.deliver9.form, "toMember", result.id);
        // })
      }
    },
    getMerchantInfo() {
      getMobileMember({ phone: this.deliver7.form.zhuanRan }).then(result => {
        this.$set(this.deliver7.form, "zheKou", result.discountDes);
        this.$set(this.deliver7.form, "fromMember", result.id);
      })
    },
    // getShouRanInfo() {
    //   if (this.deliver7.form.shouRan.length >= 11) {
    //     getMobileMember({ phone: this.deliver7.form.shouRan }).then(result => {
    //       console.log("shouRan:"+result.id)
    //       this.$set(this.deliver7.form, "toMember", result.id);
    //     })
    //   }
    // },
    // changeDiscount(e){
    //   if(e===1){
    //     this.deliver8.form.zheKou = "41";
    //   }else if(e===2){
    //     this.deliver8.form.zheKou = "51";
    //   }else if(e===4){
    //     this.deliver8.form.zheKou = "71";
    //   }else{
    //     this.deliver8.form.zheKou = "";
    //   }
    // } ,
    getAuthCodeList(authCode) {
      if (authCode) {
        let index = authCode.indexOf("-");
        if (index < 0) {
          index = authCode.length - 1;
        } else {
          index--;
        }
        let ownAuthCode = authCode.slice(index - 3, index + 1);
        if (ownAuthCode) {
          const authCodeList = authCode.split(ownAuthCode);
          authCodeList.splice(1, 0, ownAuthCode);
          return authCodeList;
        }
      }
      return [];
    },
    getUserCode(shareholder,type,level){
      // debugger;
      if(shareholder=='1'){
        //股东判断
        return '股东'
      }
      if(type=='store'){
        return '门店'
      }else if(type=='center') {
        // return '服务中心';
        return 'SF';
      }else if(type=='president') {
        // return '运营总裁';
        return 'SY'
      }else if(type=='normal'){
        if(level=='center'){
          // return '备选服务';
          return 'BF';
        } else if(level=='president'){
          // return '备选运营';
          return 'BY';
        }
        // return '用户';
        return 'Y';
      }else{
        return '';
      }
    },
    //更改剩余积分时候触发
    handleChangeCashBalance(e){
      const regex = /^[0-9]\d*$/;
      const value = event.target.value;
      if (!regex.test(value)) {
        let amount = this.initValue();
        this.deliver1.form.merchantAmount = amount - this.deliver1.form.userCashBalance;
        this.deliver1.form.userCashBalance = "";
        this.$message.info('只能输入正整数！');
        return;
      }
      // debugger;
      if( this.deliver1.form.merchantDiscount < this.deliver1.form.userCashBalance){
        this.$message.info('积分不能大于可用积分');
        this.deliver1.form.userCashBalance = this.deliver1.form.tempUserCashBalance;

        let totalAmount = this.initValue();
        this.deliver1.form.merchantAmount = totalAmount;//更新应收金额
        return;
      }
      if(this.deliver1.form.originalMerchantAmount <= this.deliver1.form.userCashBalance){
        //如果可用积分大于实付价格，那么将使用可用积分设置为实收金额
        this.deliver1.form.userCashBalance = this.deliver1.form.originalMerchantAmount;
      }
      //计算实付金额
      this.deliver1.form.merchantAmount = this.deliver1.form.originalMerchantAmount - this.deliver1.form.userCashBalance;
    },
    //超过重制值
    initValue(){
      let totalAmount = 0
      this.deliver1.form.userCashBalance = this.deliver1.form.userCashBalance? this.deliver1.form.userCashBalance:0;
      if(this.deliver1.form.originalMerchantAmount > this.deliver1.form.userCashBalance){
        totalAmount = this.deliver1.form.originalMerchantAmount -  this.deliver1.form.userCashBalance;
        // this.deliver1.form.userCashBalance = totalAmount;
      }else{
        totalAmount = 0;
        this.deliver1.form.userCashBalance = this.deliver1.form.originalMerchantAmount;
      }
      return totalAmount;
    }
  }
}
</script>

<style scoped>
.color-red a{
  color: #E62734 !important;
}

.font-red{
  color: #E62734 !important;
}

.font-black{
  color: #BBBBBB !important;
}
</style>
